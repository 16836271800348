/**
 * CONTINENT PAGE MIDDLEWARE *************************
 * *************************************************
 *
 * This middleware is executed for URLs in following the path format:
 * <property-type>/<continent>/
 *
 * It must know how to handle the following paths:
 *
 * #### NATIVE STATICWEB - WITH VALID PATHS:
 * /hostels/north-america/
 * /pt/albergues/america-do-norte/
 * /it/ostelli/nord-america/
 *
 * #### NATIVE STATICWEB - INVALID PROPERTY TYPE:
 * /banana/europe/            ---> /hostels/europe/
 * /pt/banana/europa/         ---> /pt/albergues/europa/
 * /pt/banana/north-america/  ---> /pt/albergues/america-do-norte/
 * /it/banana/europa/         ---> /it/ostelli/europa/
 * /it/banana/nord-america/   ---> /it/ostelli/nord-america/
 *
 * #### NATIVE STATICWEB - WITH LANGUAGE MISMATCH:
 * /hostels/america-do-norte/   ---> /hostels/north-america/
 * /albergues/america-do-norte/ ---> /hostels/north-america/
 * /pt/albergues/north-america  ---> /pt/albergues/america-do-norte/
 * /pt/hostels/north-america    ---> /pt/albergues/america-do-norte/
 * /it/ostelli/north-america    ---> /it/ostelli/nord-america/
 * /it/hostels/north-america    ---> /it/ostelli/nord-america/
 *
 * #### OLD RWD PATHS (AKA REDIRECTION API)
 * /hostels/lisbon/ (City)          ---> /hostels/europe/portugal/lisbon/
 * /hostels/italy/ (Country)        ---> /hostels/europe/italy/
 * /pt/albergues/lisboa/ (City)     ---> /pt/albergues/portugal/lisboa/
 * /pt/albergues/italia/ (Country)  ---> /pt/albergues/italia/
 *
 * #### INVALID COUNTRY (AKA 404)
 * /hostels/banana/
 * /pt/albergues/banana/
 */

import { useApiContinents } from '@/composables/useApiContinents';
import { useRoutes } from '@/composables/useRoutes';
import { useUrls } from '@/composables/useUrls';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getContinentByName } = useApiContinents();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE CONTINENT WITH CALL TO SPAPI
    const locationObj = await getContinentByName(propertyTypeObj?.key, urlParams.continent);

    if (locationObj?.id) {
      // CONTINENT SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const continentPageUrlForCurrentLang = useUrl.getContinentPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const continentPagePathForCurrentLang = new URL(continentPageUrlForCurrentLang).pathname.toLowerCase();

      if (continentPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(continentPageUrlForCurrentLang, {
          redirectCode: 301,
          external: true,
        });
      }
    } else {
      // CONTINENT IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Continent '${urlParams.continent}'`;
      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlPath),
      });
    }
  }
});
